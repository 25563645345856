import moment from "moment"
import PropTypes from "prop-types"
import React, { useEffect, useMemo } from "react"
import styled from "styled-components"

import AppButton from "../../../../../_shared/components/AppButton"
import AppDateRangePicker from "../../../../../_shared/components/FormItems/AppDateRangePicker"
import AppInput from "../../../../../_shared/components/FormItems/AppInput"
import AppSelect from "../../../../../_shared/components/FormItems/AppSelect"
import ImageUploadInput from "../../../../../_shared/components/FormItems/ImageUploadInput"
import { useStateValue } from "../../../../../_shared/context/AppStateStore"
import useRestTable from "../../../../../_shared/hooks/usePaginatedRestResource"
import ColorPicker from "../../../../../_shared/components/ColorPickerPopoverTag"

// ----------------------------------------------------------------------------

function _HeroEditor(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className, values, onChange, onDelete } = props

    // -------------------------------------
    // Hooks (e.g. useState, useMemo ...)
    // -------------------------------------
    const [{ token }] = useStateValue()
    const [filters] = useRestTable("/storeden/filters", token)
    // const [worlds] = useRestTable("/worlds")
    const [storedenCategories] = useRestTable("/storeden/categories", token)
    const [brands] = useRestTable("/storeden/brands", token)

    //const [worlds] = []

    // const [storedenCategories] = []
    // const [brands] = []

    // const worldOptions = useMemo(() => {
    //     return (
    //         worlds?.map((w) => ({ label: w.name, value: w.storedenId })) ?? []
    //     )
    // }, [worlds])

    const brandOptions = useMemo(() => {
        return brands?.map((brand) => ({ label: brand.name, value: brand.uid }))
    }, [brands])

    const categoryOptions = useMemo(() => {
        const options = []

        storedenCategories
            // ?.filter((c) => {
            //     return worlds?.some(
            //         (w) => String(w.storedenId) === String(c.parentUID)
            //     )
            // })
            ?.forEach((c) => {
                // const world = worlds?.find(
                //     (w) => String(w.storedenId) === String(c.parentUID)
                // )
                options.push({
                    label: ` ${c.name}`,
                    value: c.uid,
                })

                storedenCategories
                    ?.filter((category) => {
                        return category.parentUID === c.uid
                    })
                    ?.map((tier3Category) => {
                        options.push({
                            label: `${c.name} >> ${tier3Category.name}`,
                            value: tier3Category.uid,
                        })
                    })
            })

        options.sort((a, b) => a.label.localeCompare(b.label))

        return options
    }, [storedenCategories])

    const filterTargetEntityOptions = useMemo(() => {
        if (storedenCategories?.length) {
            const firstLevelCategories = storedenCategories
            // Only get those categories that are direct children of a world
            // ?.filter((c) =>
            //     worlds?.some(
            //         (w) => String(w.storedenId) === String(c.parentUID)
            //     )
            // )
            const options = [...firstLevelCategories]?.map((c) => {
                if (c.parentUID) {
                    // const parentWorld = worlds?.find(
                    //     (w) => String(w.storedenId) === String(c.parentUID)
                    // )
                    return {
                        label: `${c.name}`,
                        value: c.uid,
                    }
                } else {
                    return { label: c.name, value: c.storedenId }
                }
            })
            options.sort((a, b) => a.label.localeCompare(b.label))
            return options
        } else {
            return []
        }
    }, [storedenCategories])

    // -------------------------------------
    // Effects
    // -------------------------------------

    useEffect(() => {
        values?.targetType && onChange("target")("")
    }, [values?.targetType])

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function getViewingPeriod() {
        const period = values?.viewingPeriod ?? {}
        const { start, end } = period
        return start && end ? [moment(start), moment(end)] : null
    }

    function handleViewingPeriodChange(newValues) {
        const handler = onChange("viewingPeriod")

        if (newValues) {
            const [start, end] = newValues.map((date) => {
                return date
            })

            handler({ start, end })
        } else {
            handler(null)
        }
    }

    function handleDelete() {
        onDelete && onDelete(values)
    }

    function getFilterKeyOptions() {
        const baseOptions =
            filters?.map((f) => ({ label: f.key, value: f.key })) ?? []
        return [{ label: "Tag", value: "tag" }, ...baseOptions]
    }

    function getFilterValueOptions(filter) {
        if (filter?.key) {
            const values = filters?.find((f) => f.key === filter.key)?.values
            return (
                values &&
                Object.values(values)?.map((v) => ({ label: v, value: v }))
            )
        } else return []
    }

    function renderFilterSelector() {
        return (
            <div className="filter-selector">
                <div className="filters">{renderFilters()}</div>
                <AppButton
                    className="add-filter"
                    uxType="secondary"
                    htmlType="button"
                    onClick={handleAddFilter}
                >
                    Aggiugni filtro
                </AppButton>
            </div>
        )
    }

    function renderFilters() {
        const filters =
            (Array.isArray(values?.filters) ? values?.filters : []) ?? []
        return filters?.map((filter) => (
            <div className="filter" key={filter.id}>
                <AppSelect
                    options={getFilterKeyOptions()}
                    placeholder="Chiave"
                    className="select first"
                    label="Campo filtro"
                    value={filter?.key}
                    onChange={handleUpdateFilterKey(filter)}
                />
                {filter?.key === "tag" ? (
                    <AppInput
                        value={filter?.value}
                        onChange={handleUpdateFilterValue(filter)}
                        label="Valore tag"
                        className="select"
                    />
                ) : (
                    <AppSelect
                        value={filter?.value}
                        placeholder="Valore"
                        className="select"
                        label="Valore filtro"
                        options={getFilterValueOptions(filter)}
                        onChange={handleUpdateFilterValue(filter)}
                    />
                )}
                <AppButton
                    className="delete-button"
                    uxType="delete"
                    htmlType="button"
                    onClick={handleDeleteFilter(filter)}
                >
                    Elimina
                </AppButton>
            </div>
        ))
    }

    function handleAddFilter() {
        const handler = onChange("filters")
        const prevFilters = Array.isArray(values.filters) ? values.filters : []
        handler &&
            handler([
                ...(prevFilters ?? []),
                {
                    key: "",
                    value: "",
                    id: Math.random().toString(),
                },
            ])
    }

    function handleUpdateFilterValue(filter) {
        return (value) => {
            const handler = onChange("filters")
            const newFilters = values?.filters?.map((f) =>
                f.id === filter?.id ? { ...f, value: value } : f
            )
            handler(newFilters)
        }
    }

    function handleUpdateFilterKey(filter) {
        return (value) => {
            const handler = onChange("filters")
            const newFilters = values?.filters?.map((f) =>
                f.id === filter?.id ? { ...f, key: value, value: "" } : f
            )
            handler(newFilters)
        }
    }

    function handleDeleteFilter(filter) {
        return () => {
            const handler = onChange("filters")
            const newFilters = values?.filters?.filter(
                (f) => f.id !== filter?.id
            )
            handler(newFilters ?? [])
        }
    }

    function getTargetOptions() {
        switch (values?.targetType) {
            case "category":
                return categoryOptions
            case "editorial":
                return []
            case "brand":
                return brandOptions
            case "filter":
                return filterTargetEntityOptions
            default:
                return []
        }
    }

    function getTargetFilterOption() {
        if (values?.targetType === "brand") {
            return (input, option) => {
                const pat = new RegExp(input?.toLowerCase())
                return pat.test(option?.children?.toLowerCase())
            }
        }
        if (values?.targetType === "category") {
            return (input, option) => {
                const pat = new RegExp(input?.toLowerCase())
                return pat.test(option?.children?.toLowerCase())
            }
        }
        return undefined
    }

    function renderTargetTypeSelector() {
        switch (values?.targetType) {
            case "filter": {
                return (
                    <>
                        <AppSelect
                            value={values?.target}
                            onChange={onChange("target")}
                            options={getTargetOptions()}
                            className="input"
                            placeholder="Seleziona target"
                            label="Target"
                            allowClear
                        />
                        {renderFilterSelector()}
                    </>
                )
            }
            case "editorial": {
                return (
                    <AppInput
                        label="Url editoriale"
                        value={values?.target}
                        onChange={onChange("target")}
                        className="input"
                    ></AppInput>
                )
            }

            case "tag": {
                return (
                    <AppInput
                        label="Tag"
                        value={values?.target}
                        onChange={onChange("target")}
                        className="input"
                    ></AppInput>
                )
            }

            default: {
                return (
                    <AppSelect
                        value={values?.target}
                        onChange={onChange("target")}
                        options={getTargetOptions()}
                        filterOption={getTargetFilterOption()}
                        className="input"
                        placeholder="Seleziona target"
                        label="Target"
                    />
                )
            }
        }
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    const destinationOptions = [
        { label: "Categoria", value: "category" },
        { label: "Brand", value: "brand" },
        { label: "Editoriale", value: "editorial" },
        { label: "Filtro", value: "filter" },
        { label: "Tag", value: "tag" },
    ]

    const viewOptions = [
        { label: "Con margine", value: "margin" },
        { label: "Senza margine", value: "fullwidth" },
    ]

    const widgetOptions = [
        // { label: "Standard", value: "standardWidget" },
        { label: "Prodotti filtrati", value: "filteredProduct" },
        { label: "Immagine con CTA", value: "imageWithCTA" },
        // { label: "Blog", value: "blogWidget" },
    ]

    function returnStandardWidget() {
        return (
            <div className={`${className}`}>
                <AppSelect
                    className="input"
                    value={values?.subType}
                    options={widgetOptions}
                    onChange={onChange("subType")}
                    placeholder="Seleziona SottoTipo"
                    label="SottoTipo"
                />
                <AppButton
                    uxType="delete"
                    htmlType="button"
                    className="delete-btn"
                    onClick={handleDelete}
                >
                    Elimina widget
                </AppButton>
            </div>
        )
    }

    function returnImageWithCTAWidget() {
        return (
            <div className={`${className}`}>
                <AppSelect
                    className="input"
                    value={values?.subType}
                    options={widgetOptions}
                    onChange={onChange("subType")}
                    placeholder="Seleziona SottoTipo"
                    label="SottoTipo"
                />
                <AppInput
                    className="input"
                    value={values?.title}
                    onChange={onChange("title")}
                    label="Titolo"
                />

                <AppInput
                    className="input"
                    value={values?.buttonText}
                    onChange={onChange("buttonText")}
                    label="Testo bottone"
                />

                <AppDateRangePicker
                    label="Periodo di visualizzazione"
                    className="input"
                    value={getViewingPeriod()}
                    onChange={handleViewingPeriodChange}
                />

                <AppSelect
                    value={values?.targetType}
                    onChange={onChange("targetType")}
                    options={destinationOptions}
                    className="input"
                    placeholder="Seleziona categoria"
                    label="Categoria"
                />

                {renderTargetTypeSelector()}

                <ImageUploadInput
                    image={values?.image}
                    onUpload={onChange("image")}
                />

                <AppButton
                    uxType="delete"
                    htmlType="button"
                    className="delete-btn"
                    onClick={handleDelete}
                >
                    Elimina widget
                </AppButton>
            </div>
        )
    }

    function returnFilteredProductsWidget() {
        return (
            <div className={`${className}`}>
                <AppSelect
                    className="input"
                    value={values?.subType}
                    options={widgetOptions}
                    onChange={onChange("subType")}
                    placeholder="Seleziona SottoTipo"
                    label="SottoTipo"
                />
                <AppInput
                    className="input"
                    value={values?.title}
                    onChange={onChange("title")}
                    label="Titolo"
                />

                <AppDateRangePicker
                    label="Periodo di visualizzazione"
                    className="input"
                    value={getViewingPeriod()}
                    onChange={handleViewingPeriodChange}
                />

                <AppSelect
                    value={values?.targetType}
                    onChange={onChange("targetType")}
                    options={destinationOptions}
                    className="input"
                    placeholder="Seleziona categoria"
                    label="Categoria"
                />
                <span className="field-label">Colore testo</span>
                <ColorPicker
                    color={values?.textColor}
                    onSave={(color) => onChange("textColor")(color.hex)}
                    className="color-picker"
                />
                <span className="field-label">Colore sfondo</span>
                <ColorPicker
                    color={values?.backgroundColor}
                    onSave={(color) => onChange("backgroundColor")(color.hex)}
                    className="color-picker"
                />
                {renderTargetTypeSelector()}

                <AppButton
                    uxType="delete"
                    htmlType="button"
                    className="delete-btn"
                    onClick={handleDelete}
                >
                    Elimina widget
                </AppButton>
            </div>
        )
    }

    function getWidget() {
        switch (values?.subType) {
            case "standardWidget": {
                return returnStandardWidget()
            }
            case "filteredProduct": {
                return returnFilteredProductsWidget()
            }
            case "imageWithCTA": {
                return returnImageWithCTAWidget()
            }

            default: {
                return returnStandardWidget()
            }
        }
    }

    return getWidget()
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_HeroEditor.propTypes = {
    className: PropTypes.string.isRequired,
    values: PropTypes.object,
    onChange: PropTypes.func,
    onDelete: PropTypes.func,
}

_HeroEditor.defaultProps = {}

// ----------------------------------------------------------------------------

const HeroEditor = styled(_HeroEditor)`
    & {
        .delete-btn {
            margin-top: 40px;
            width: 100%;
            height: 48px;
            /* max-width: unset; */
        }
        .input {
            margin-bottom: 20px;
            max-width: 300px;
        }
        .color-picker {
            width: 250px;
            margin-bottom: 20px;
            .tag {
                border: 1px solid black;
            }
        }
        .field-label {
            margin-bottom: 10px;
            display: block;
        }
        .filter-selector {
            margin: 20px 0px;

            .filters {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                overflow-x: auto;
                margin-bottom: 10px;
                .filter {
                    width: 200px;
                    margin: 20px 0px;
                    padding-right: 25px;
                    min-width: 200px;

                    &:first-child {
                        width: 175px;
                        min-width: 175px;
                    }

                    &:not(:first-child) {
                        padding-left: 25px;
                    }

                    &:not(:last-child) {
                        border-right: 1px solid
                            ${({ theme }) => theme.colors.primary};
                    }

                    .filter-label {
                        display: block;
                        margin: 5px 0px;
                        font-weight: 500;
                    }

                    .select {
                        flex: 1;
                        min-width: unset;
                        margin-bottom: 20px;
                        &.first {
                            margin-bottom: 10px;
                        }
                    }
                    .delete-button {
                        width: 100%;
                        font-size: 14px;
                        font-weight: 400;
                        height: 30px;
                    }
                }
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default HeroEditor
