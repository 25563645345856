import React, { useEffect, useState } from "react"
import { message } from "antd"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Tag, Statistic, Card } from "antd"
import { Link, Route, Switch, useLocation } from "react-router-dom"
import * as api from "../../../../api/Local"
import usePaginatedRestResource from "../../../_shared/hooks/usePaginatedRestResource"
import { useStateValue } from "../../../_shared/context/AppStateStore"

// ----------------------------------------------------------------------------

function _TotemsStore(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props

    const [{ token }] = useStateValue()

    const [
        stores,
        fetchBanners,
        loading,
        pagination,
        totalRecordsNumber,
        onFiltersChange,
    ] = usePaginatedRestResource("/totemstore", token)
    function renderStores() {
        return (
            <div className="routes">
                {stores?.map((x) => (
                    <Link
                        key={x?.id}
                        className="route"
                        to={{
                            pathname: `/totem/${x.slug}`,
                            params: { store: x },
                        }}
                    >
                        {x.name} ({x?.mode === "1" ? "TOTEM" : "APP"})
                    </Link>
                ))}
            </div>
        )
    }

    return (
        <div className={`${className}`}>
            <h1 className="page-title">Totem nei tuoi punti vendita</h1>
            <Switch>
                <Route exact path="/totemsStore" component={renderStores} />
            </Switch>
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_TotemsStore.propTypes = {
    className: PropTypes.string.isRequired,
}

_TotemsStore.defaultProps = {}

// ----------------------------------------------------------------------------

const TotemsStore = styled(_TotemsStore)`
    & {
        .routes {
            width: 100%;
            .route {
                height: 50px;
                width: 100%;
                padding-left: 10px;
                display: flex;
                align-items: center;
                border-bottom: 1px solid ${({ theme }) => theme.colors.grey500};
                color: ${({ theme }) => theme.colors.textPrimary};
                transition: all 200ms ease;
                font-size: 16px;

                &:hover {
                    background-color: ${({ theme }) => theme.colors.grey200};
                }
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default TotemsStore

function getColumns(renderActions) {
    return [
        {
            key: "title",
            title: "Titolo",
            dataIndex: "title",
        },
        {
            key: "body",
            title: "Testo",
            dataIndex: "body",
        },
        {
            key: "published",
            title: "",
            dataIndex: "published",
            //eslint-disable-next-line
            render: (published) =>
                published && <Tag color="green">PUBBLICATA</Tag>,
        },
        {
            key: "actions",
            render: renderActions,
        },
    ]
}
